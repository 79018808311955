// @import 'Styles/colors';
@import "../../../../../Styles/colors";
@import "../../../../../Styles/mixins";
// @import 'Styles/mixins';

.display-links {
  .MuiButton-containedPrimary {
    background-color: #0d1324;
  }
}
