/* ---------------- */
// FONTS
/* ---------------- */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700&display=swap");
@import "./Styles/colors";

@font-face {
  font-family: "Causten";
  src: url(./Assets/Fonts/Causten-Bold.otf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Causten";
  src: url(./Assets/Fonts/Causten-SemiBold.otf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Causten";
  src: url(./Assets/Fonts/Causten-Regular.otf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@mixin main-font() {
  font-family: "Causten", "Mukta", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}

/* ---------------- */
// GENERAL
/* ---------------- */

body {
  margin: 0;
}

* {
  @include main-font();
}

.MuiTypography-root {
  @include main-font();
}

.MuiListItemIcon-root {
  min-width: 32px !important;
}

/* ---------------- */
// ADMIN VIEWS & ADMIN DETAILS (GLOBAL)
/* ---------------- */

.admin-view {
  height: auto;
  flex-wrap: nowrap !important;
  flex-direction: column;
  background: $ad-light-100;
  max-width: 100%;
  overflow: auto;
}

.admin-details {
  height: auto;
  flex-wrap: nowrap !important;
  flex-direction: column;
  background: $ad-light-100;
  max-width: 100%;
  overflow: auto;
}

/* ---------------- */
// ADMIN VIEW HEADER
/* ---------------- */

.admin-view-header {
  padding: 30px 50px 29px;
  row-gap: 35px;
  display: flex;
  align-items: center;
  background: $ad-white;
  flex-basis: 0 !important;
  // min-height: 130px;
  &.adding {
    flex-direction: column;
    flex-basis: unset !important;
    min-height: auto;

    .add-grid-button {
      justify-content: flex-end;
      gap: 20px;
    }

    .add-form {
      display: flex;
      flex-direction: column;
      row-gap: 45px;
      width: 100%;

      .add-grid-form {
        gap: 15px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  .show-grid-button {
    width: auto;
    .show-button {
      margin: 0;
    }
  }
}

/* ---------------- */
// ADMIN INFOS & BREADCRUMS
/* ---------------- */

.admin-infos {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  .header-title {
    color: $ad-dark-100;
    font-size: 32px;
    font-weight: 700;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;

    .header-page-name {
      text-transform: capitalize;
    }

    .school-logo {
      width: 50px;
      height: 50px;
      border-radius: 50px;
    }
  }
  .admin-breadcrum {
    margin: 0;
    text-transform: uppercase;
    color: $ad-grey-700;

    .MuiBreadcrumbs-li {
      color: $ad-grey-700;

      .MuiTypography-root {
        font-weight: 700;
        font-family: "Causten", "Mukta", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
          "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
          sans-serif;
      }

      a {
        color: $ad-grey-700;
        text-decoration: underline;
        font-weight: 700;
        transition: 0.2s;

        &:hover {
          color: $ad-green-500;
        }
      }
    }
    .MuiBreadcrumbs-separator {
      color: $ad-grey-700;
    }
  }
}

/* ---------------- */
// ADMIN VIEWS TABLE
/* ---------------- */

.admin-view-table {
  height: auto;
  padding: 50px;
  flex-basis: 0 !important;

  .admin-view-section {
    display: flex;
    gap: 25px;
    flex-direction: column;

    .section-title {
      font-size: 24px;
      font-weight: 700;
      margin: 0;
    }
    // PAPER & SEARCHBAR
    .MuiPaper-root {
      box-shadow: none;
      background: none;
      display: flex;
      gap: 25px;
      flex-direction: column;

      .search-bar {
        display: flex;

        .MuiToolbar-root {
          padding: 0;

          div {
            &:first-child {
              flex: 1;
            }
          }
        }

        .MuiFormControl-root {
          padding: 0;
        }

        .Mui-focused .MuiOutlinedInput-notchedOutline {
          border-color: $ad-green-600;
        }
      }
    }

    // TABLE HEADER
    .MuiTableCell-head {
      background: none;
      border-bottom: none;
      white-space: nowrap;
      width: 1% !important;
      &.MuiTableCell-alignCenter {
        width: auto !important;
        div {
          &:first-of-type {
            justify-content: center;
            .MuiSvgIcon-root {
              display: none;
            }
            &:hover {
              .MuiSvgIcon-root {
                display: block;
              }
            }
          }
        }
      }
      .MuiTableSortLabel-root {
        color: $ad-grey-500;
        font-size: 13px;
        line-height: 17px;
      }
    }

    // TABLE BODY
    .MuiTableBody-root {
      .MuiTableCell-root {
        height: 65px;
        background: $ad-white;
        padding: 15px 20px;
        min-width: 100px;
        &.MuiTableCell-alignCenter {
          white-space: nowrap;
          width: fit-content !important;
        }
        &.MuiTableCell-paddingNone {
          div {
            &:first-child {
              justify-content: center;
            }
          }
        }
        .logo-img {
          width: 100%;
          max-width: 40px;
          height: auto;
          border-radius: 100%;
          image-rendering: -webkit-optimize-contrast;
        }
      }
      .MuiTableRow-root {
        &:first-child {
          .MuiTableCell-root {
            &:first-child {
              border-top-left-radius: 5px;
            }
            &:last-child {
              border-top-right-radius: 5px;
            }
          }
        }
        &:last-child {
          .MuiTableCell-root {
            &:first-child {
              border-bottom-left-radius: 5px;
            }
            &:last-child {
              border-bottom-right-radius: 5px;
            }
          }
        }
      }
    }
  }
}

/* ---------------- */
// ADMIN DETAILS
/* ---------------- */

.admin-details {
  background: $ad-light-100;
}

.admin-infos-return {
  display: flex;
  gap: 15px;
  align-items: center;
}

.admin-detail-content {
  padding: 50px;
}

/* ---------------- */
// DETAILS CONTENT
/* ---------------- */

.details-content-form {
  gap: 40px;
  display: flex;
  flex-direction: column;
  width: 100%;

  .details-content-card {
    box-shadow: none;

    .details-card {
      box-shadow: none;
      background: none;

      .details-card-main {
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .details-card-header {
          padding: 0;
          .MuiTypography-root {
            font-size: 24px;
            color: $ad-dark-100;
            font-weight: 700;
          }
        }
        .details-card-content {
          padding: 30px;
          background: $ad-white;
          border-radius: 5px;

          .details-card-form {
            gap: 25px;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
            .MuiGrid-item {
              width: 100%;
              min-width: 100%;
              padding: 0;
            }
          }
        }
      }
    }
  }
  .details-content-save {
    justify-content: flex-end;
    gap: 20px;
  }
}

/* ---------------- */
// SCHOOL DETAILS - FORMATIONS
/* ---------------- */

.city-card {
  gap: 30px;
  display: flex;
  flex-direction: column;
  .city-manager {
    flex-wrap: nowrap;
    gap: 20px;
  }
}

/* ---------------- */
// SCHOOL DETAILS - FORMATIONS
/* ---------------- */

.school-details-formations {
  .formations-item {
    flex-direction: column;
    gap: 35px;
    display: flex;

    .show-grid-button {
      justify-content: flex-end;
    }

    .add-form {
      gap: 30px;
      display: flex;
      flex-direction: column;
      .add-grid-form {
        gap: 25px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      }
      .add-grid-button {
        gap: 15px;
        justify-content: flex-end;
      }
    }

    // SEARCH BAR (MERGE WITH FIRST SEARCH BAR CSS)
    .MuiPaper-root {
      box-shadow: none;

      .search-bar {
        display: flex;

        .MuiToolbar-root {
          padding: 0;

          div {
            &:first-child {
              flex: 1;
            }
          }

          .MuiFormControl-root {
            padding: 0;
          }

          .Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: $ad-green-600;
          }
        }
      }
    }
  }
}

/* ---------------- */
// FORMATIONS CHECKBOXES
/* ---------------- */

.formations-checkboxes-main {
  .formations-list-legend {
    padding-bottom: 15px;
    color: $ad-grey-700;
  }
}
.formations-checkboxes-main {
  .form-switch {
    .MuiSwitch-colorSecondary.Mui-checked {
      color: $ad-green-500;
    }
    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
      background-color: $ad-green-500;
    }
    .MuiSwitch-colorSecondary.Mui-checked:hover {
      background-color: rgba(56, 199, 149, 0.04);
    }
    .MuiSwitch-track {
      background-color: $ad-grey-300;
    }
  }
  .MuiFormLabel-root {
    color: $ad-grey-500;
    &.Mui-focused {
      color: $ad-grey-500;
    }
  }
}

/* ---------------- */
// REF TABLES
/* ---------------- */

.admin-view-ref {
  .ref-nav {
    display: flex;
    flex-direction: column;

    .ref-link {
      text-decoration: none;

      .MuiButtonBase-root {
        &:hover {
          background: $ad-green-600;
        }
        &:active {
          background: none;
        }
        .ref-link-icon {
          color: $ad-dark-100;
        }
        .ref-link-label {
          color: $ad-dark-100;
          .MuiTypography-root {
            font-weight: 700;
          }
        }
      }
    }
  }
}

/* ---------------- */
// BUTTONS
/* ---------------- */

.ad-main-button {
  border: 0 !important;
  &.main-color {
    background-color: $ad-green-500;
    color: $ad-white;
    &:hover {
      background: $ad-green-600;
    }
  }
  &.dark-color {
    background-color: $ad-dark-100;
    color: $ad-white;
    &:hover {
      background: $ad-dark-400;
    }
  }
  &.lg {
    font-size: 16px;
    font-weight: 700;
    padding: 8px 25px 10px;
  }
}

.ad-stroke-button {
  border-width: 1px !important;
  border-style: solid !important;

  &.dark-color {
    border-color: $ad-dark-100;
    color: $ad-dark-100;
    transition: 0.2s;

    &:hover {
      border-color: $ad-green-600;
      color: $ad-green-600;
      background: none;
    }
  }
  &.icon-button {
    width: 37px;
    height: 37px;

    .MuiSvgIcon-root {
      width: 16px;
    }
  }
}

/* ---------------- */
// CHIP
/* ---------------- */

.chip {
  border: none !important;
  font-weight: 700;
  .MuiChip-label {
    padding: 0;
  }
  &.complete {
    background: $ad-status-green-light;
    color: $ad-status-green-dark;
  }
  &.to-complete {
    background: $ad-status-red-light;
    color: $ad-status-red-dark;
  }
  &.niv {
    background: $ad-status-black-light;
    color: $ad-status-black-dark;
  }
  &.niv-5 {
    background: $ad-status-lavender-light;
    color: $ad-status-lavender-dark;
  }
  &.niv-6 {
    background: $ad-status-purple-light;
    color: $ad-status-purple-dark;
  }
  &.niv-7 {
    background: $ad-status-yellow-light;
    color: $ad-status-yellow-dark;
  }
  &.md {
    padding: 8px 20px;
  }
}
