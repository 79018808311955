$black: #0d1324;
$white: #ffffff;
$white10: #161e35;
$white20: #273252;
$white30: #3b4666;
$white40: rgb(117, 119, 139);
$white60: rgb(136, 139, 170);
$white80: rgb(197, 199, 223);
$white90: #f2f4fd;
$white-background: #f2f4fd;

$greytext: #a9a9c2;

$btest: #1e2431;
$green: #42b391;
$green-light: #61d2aa;
$green-dark: #339b7b;
// $white: #eff2fc;
$white-light: #ffffff;
$white-dark: #d6dae8;

$white-grey: #bdc4cc;
$grey: #86919d;
$dark-grey: #2c3040;

$red: #e33939;

$yellow-light: #fdf5e3;
$yellow-dark: #f5b92a;
$blue-light: #e2f2ff;
$blue-dark: #0795ff;
$pink-light: #fbe5f6;
$pink-dark: #eb88d6;

/* ---------------- */
// COLORS V2
/* ---------------- */

$ad-white: #ffffff;
$ad-light-100: #f2f4fd;
$ad-dark-050: hsl(224, 92%, 05%);
$ad-dark-100: hsl(224, 47%, 10%);
$ad-dark-400: rgb(36, 40, 56);
$ad-green-800: hsla(159, 56%, 80%, 1);
$ad-green-700: hsla(159, 56%, 70%, 1);
$ad-green-600: hsla(159, 56%, 60%, 1);
$ad-green-500: hsla(159, 56%, 50%, 1);
$ad-green-400: hsla(159, 56%, 40%, 1);
$ad-green-300: hsla(159, 56%, 30%, 1);
$ad-grey-900: hsla(211, 11%, 90%, 1);
$ad-grey-700: hsla(211, 11%, 70%, 1);
$ad-grey-500: hsla(211, 11%, 57%, 1);
$ad-grey-300: hsla(211, 11%, 30%, 1);

$ad-status-green-light: #beffd7;
$ad-status-green-dark: #196047;
$ad-status-red-light: #ffd3d3;
$ad-status-red-dark: #833030;
$ad-status-lavender-light: #d5defd;
$ad-status-lavender-dark: #385789;
$ad-status-yellow-light: #fff0c7;
$ad-status-yellow-dark: #625c2c;
$ad-status-purple-light: #f3c6fc;
$ad-status-purple-dark: #722b83;
$ad-status-orange-light: hsl(29, 90%, 88%);
$ad-status-orange-dark: hsl(29, 90%, 35%);
$ad-status-black-light: #c5c7df;
$ad-status-black-dark: #0d1324;
$ad-status-brown-light: #F7A499;
$ad-status-brown-dark: #22253A;
