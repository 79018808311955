// @import 'Styles/colors';

@import '../../../Styles/colors';
@import '../../../Styles/mixins';
// @import 'Styles/mixins';

// .manage-progress{
//     border-radius: 5px;
//     padding: 40px;
//     color: $black;
//     border: 1px solid  #BDC4CC;
//     position: sticky;
//     top: 0;
//     z-index: 10;

//     .s-title{
//         font-size:1.3em;
//         font-weight:800;
//         margin: 0 0 10px;;
//     }

// }

// .progress-section{
//     display: flex;
//     align-items: center;
//     padding: 10px 0;

//     .icon {
//         height: 42px;
//         width: 45px;
//         display: flex;
//         align-items: center;
//         justify-content: center;

//         & > div {
//             height: 32px;
//         }

//         .MuiSvgIcon-root{
//             font-size: 2rem;
//             color: $green-light;
//         }
//     }

//     .infos{
//         padding-left: 5px;
//         display: flex;
//         justify-content: space-between;
//         width: 100%;

//         .title{
//             font-weight: 800;
//             font-size: 1.1em;
//             margin: 0;
//         }

//         .stat{
//             font-weight: 500;
//             font-size: 1.1em;
//             margin: 0;
//             color: $grey;
//         }
//     }
// }

// .Export {
//   display: flex;
//   justify-content: center;
//   margin-top: 24px;
// }

// .main-progress {
//   padding: 15px 35px;
//   background: $white;
//   @include box-shadow(
//     $h: 0px,
//     $v: 3px,
//     $b: 12px,
//     $s: 1px,
//     $c: rgba(13, 19, 36, 0.1)
//   );
//   z-index: 1;
//   .manage-progress {
//     @include flexbox;
//     @include flex-direction(row);
//     @include justify-content(space-around);
//     .cpn-progress {
//       @include flexbox;
//       @include align-items(center);
//       @include flex-grow(2);
//       padding-left: 25px;
//       max-width: 700px;
//       .ctn-progress {
//         width: 100%;
//         .ctn-title {
//           margin: 0;
//           @include font-size(16px);
//           font-weight: 600;
//           text-transform: uppercase;
//           color: $white80;

//           .progress-percentage {
//             @include font-size(16px);
//             font-weight: 600;
//             color: $green;
//           }
//         }
//         .ctn-info-progress {
//           @include flexbox;
//           @include flex-direction(row);
//           @include align-items(center);
//           @include justify-content(space-between);
//           .progress-title {
//             h2 {
//               margin: 0;
//               @include font-size(18px);
//               font-weight: 800;
//               color: $black;
//             }
//           }
//           .progress-total {
//             @include font-size(16px);
//             font-weight: 400;
//             color: $white40;
//           }
//         }
//         .ctn-progress-bar {
//           width: 100%;
//           height: 20px;
//           border-radius: 8px;
//           overflow: hidden;
//           margin-top: 10px;
//           background-color: $white-background;
//           .progress-bar {
//             height: 100%;
//             background-color: $green-light;
//           }
//         }
//       }
//     }
//     .ctn-filtre {
//       padding-right: 25px;
//       .ctn-title {
//         margin: 0;
//         @include font-size(16px);
//         font-weight: 600;
//         text-transform: uppercase;
//         color: $white80;
//         margin-bottom: 10px;
//       }
//       .filtre-title {
//         margin: 0;
//         @include font-size(23px);
//         font-weight: 800;
//         color: $black;
//       }
//       .cte-filtre {
//         @include flexbox;
//         @include flex-direction(row);
//         @include align-items(center);
//       }
//     }
//   }
// }
.progress {
  .ctn-progress {
    @include flexbox;
    @include flex-direction(row);
    @include align-items(flex-start);
    margin-bottom: 15px;
    .progress-title {
      min-width: 120px;
      @include flexbox;
      @include align-items(center);
      .pin {
        width: 13px;
        height: 13px;
        border-radius: 50px;
        &.cerfa {
          background: $yellow-dark;
        }
        &.convention {
          background: $blue-dark;
        }
      }
      .title {
        display: inline-block;
        margin: 0;
        margin-top: 3px;
        padding: 1px 12px 1px 8px;
        font-weight: 700;
        @include font-size(14px);
        border-radius: 20px;
        margin-right: 10px;
        text-transform: capitalize;
        color: white;
      }
    }

    .progress-achievement {
      @include flexbox;
      @include flex-direction(column);
      width: 100%;
      @include align-items(flex-end);
    }
    .progress-visual {
      @include flexbox;
      @include flex-direction(row);
      @include align-items(center);
      width: 100%;

      .progress-bar {
        width: 100%;
        background: $white20;
        border-radius: 10px;
        overflow: hidden;

        .bar {
          height: 8px;
          &.cerfa {
            background: $yellow-dark;
          }
          &.convention {
            background: $blue-dark;
          }
        }
      }
      .progress-percent {
        color: $white;
        font-weight: 700;
        @include font-size(16px);
        letter-spacing: 2px;
        min-width: 55px;
        text-align: right;

        .percent-symbole {
          @include font-size(12px);
          font-weight: 500;
        }
      }
    }
    .progress-number {
      .number {
        margin: 0;
        color: $white40;
        font-weight: 700;
        @include font-size(13px);
      }
    }
  }
}
