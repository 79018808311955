// @import 'Styles/colors';

@import '../../Styles/colors';
@import '../../Styles/mixins';
// @import 'Styles/mixins';

.ctp-form {
  background: $white-background;
  @include flexbox;
  @include flex-direction(row);
  height: 100vh;
  overflow: hidden;
}
.form-dashboard {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;

  .header {
    @include flexbox;
    @include flex-direction(column);
    background: $btest;
    color: $white-light;
    padding: 0 24px;

    .ctn-header {
      max-width: 1600px;
      padding: 0;
    }
  }
}

.form-section {
  background: rgba(255, 255, 255, 0.4);
  &.lock-true {
    .ctn-inputs {
      border: 1px solid $white80;
      box-shadow: none;
    }
    .title {
      opacity: 0.5;
    }
    .MuiGrid-container {
      opacity: 0.5;
    }
  }
  .cpn-dashboard {
    @include flexbox;
    @include justify-content(flex-end);

    .cpn-form {
    }

    .ctn-go-top {
      position: static;
      width: 200px;

      .go-top {
        position: absolute;
        margin: auto;
        bottom: 102px;
        @include flexbox;
        @include justify-content(center);
        @include align-items(center);
        cursor: pointer;

        .go-top-icon {
          margin: 0 5px 0 0;
          @include font-size(18px);
          color: $green;
        }
        .go-top-title {
          margin: 0;
          font-weight: 500;
          color: $green;
          @include font-size(14px);
        }
      }
    }
  }

  .main-progress {
    width: 100%;
  }
  .form-content {
    max-width: 1600px;
    margin-top: 30px;
  }

  .ctn-inputs {
    padding: 40px;
    margin-bottom: 30px;

    .section-header {
      @include flexbox;
      @include flex-direction(row);
      @include justify-content(space-between);

      .section-title {
        margin: 0;
        font-weight: 800;
        font-size: 20px;
      }

      .section-subtitle {
        font-weight: 200;
        font-size: 14px;
        line-height: 1.4em;
        color: $grey;
        margin-top: 5px;
      }

      .lock-icon {
        padding: 0 0px 0 25px;
        margin-top: -10px;
        button {
          .lock {
            color: $red;
          }
        }
      }
    }

    .section-grid {
      margin: 25px 0 0;
      width: 100%;
    }
  }

  // .ctn-sidebar {
  //   position: sticky;
  //   top: 0;
  //   padding-top: 80px;
  //   margin-top: -80px;
  // }

  .cpn-tabs {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 20;

    background: $white10;
    color: white;
  }

  .MuiGrid-item {
    flex-direction: row;
    display: flex;
  }

  /* New form */
  .MuiInputLabel-root {
    display: block;
    transform-origin: top left;
    margin-bottom: 0px;
    font-weight: 700;
    font-family: 'Mukta';
    font-size: 14px;
    color: $white40;
  }
  .input-label-top {
    display: flex;
  }
  .form-section .MuiGrid-item {
    flex-direction: row;
    display: flex;
    padding: 5px;
    flex-direction: column;
    margin-bottom: 12px;
  }
  .ctn-input {
    @include flexbox;
    @include align-items(center);
    .ctn-tooltip {
      margin-left: 5px;
    }
    .MuiOutlinedInput-input {
      font-size: 13px;
      padding: 15px;
    }
    .MuiSelect-root {
      font-size: 13px;
      padding: 13.5px;
    }

    .Mui-disabled{
      fieldset{
        border: 0;
      }
    }
  }
  .ctn-input-label {
    @include flexbox;
    @include justify-content(space-between);
    @include align-items(center);
    padding-bottom: 5px;

    .input-filter {
      @include flexbox;
      .ctn-filter {
        border-radius: 10px;
        @include flexbox;
        margin-left: 5px;

        .cte-filter-pin {
          width: 9px;
          height: 9px;
          border-radius: 50px;
          &.Cerfa {
            background-color: $yellow-dark;
          }

          &.Convention {
            background-color: $blue-dark;
          }
        }
        .cte-filter {
          margin: 0;
          padding: 1px 12px 1px;
          font-weight: 600;
          @include font-size(11px);

          border-radius: 10px;

          &.Cerfa {
            background-color: $yellow-light;
            color: $yellow-dark;
          }

          &.Convention {
            background-color: $blue-light;
            color: $blue-dark;
          }
        }
      }
    }
  }
}

.dashboard-footer {
  @include flexbox;
  @include flex-direction(row);
  @include justify-content(flex-end);
  @include align-items(center);
  padding: 25px 210px 25px 45px;
  .save {
    @include flexbox;
    @include flex-direction(row);
    @include justify-content(center);
    @include align-items(center);
    margin-right: 20px;
    .save-icon {
      margin-right: 5px;
      color: $green;
      @include font-size(20px);
    }
    .save-text {
      margin: 0;
      color: $white60;
      @include font-size(14px);
    }
  }
  .next-btn {
    background: $green;
    color: $white;
    padding: 6px 35px;
    &:hover {
      background: $green-light;
    }
  }
}

.ctn-add-siret {
  width: 100%;
  padding: 40px;
  overflow-y: scroll;

  .MuiPaper-root {
    box-shadow: none;
    max-width: 800px;
    margin: auto;
  }

  .cte-add-siret {
    padding: 40px;
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(center);
  }
  .MuiGrid-item {
    @include flexbox;
    @include flex-direction(column);
    margin: 15px 0;
    width: 100%;
    max-width: 100%;
  }
  .ctn-input-label {
    margin-bottom: 15px;
  }
  .input-filter {
    display: none !important;
  }
  .check-info-title {
    margin: 0;
    padding: 45px 0px 0px;
    font-weight: 700;
    @include font-size(20px);
    &.first-title {
      padding: 0;
    }
  }
  .check-info-subtitle {
    font-weight: 300;
    @include font-size(16px);
    line-height: 1.4em;
    color: $grey;
    margin-top: 5px;
  }
  .form-section {
    margin: 0;

    .ctn-input-label {
      margin-top: 10px;
      margin-bottom: 5px;
    }
  }
  .ctn-check-info {
    @include flexbox;
    @include flex-direction(column);
  }
  .ctn-button {
    @include flexbox;
    @include flex-direction(row);
    @include align-items(center);
    margin-top: 15px;

    .button-pass {
      color: $grey;
      @include font-size(13px);
      margin-right: 15px;
    }
    .button-add {
      color: $white;
      background: $green;
      @include font-size(13px);
      padding: 8px 35px;
    }
  }
  .button-validate {
    margin: 30px 0;
    background: $green;
    color: $white;
  }
}

.add-hide-section {
  @include flexbox;
  @include flex-direction(row);
  @include align-items(center);
  margin-top: -20px;
  margin-bottom: 0px;
  cursor: pointer;
  .ahs-icon {
    margin-right: 5px;
    &.add {
      color: $black;
    }
    &.delete {
      color: $red;
    }
  }
  .ahs-title {
    font-weight: 500;
    &.add {
      color: $black;
    }
    &.delete {
      color: $red;
    }
  }
}

.display-section {
  &.MuiFormControl-root {
    width: 100%;
    background: $white;
    @include flexbox;
    @include flex-direction(row);
    @include align-items(center);

    &.copy {
      margin-top: -60px;
    }
    &.form-parent-lock-true {
      margin-top: 0;
    }
  }

  &.open {
    &.copy {
      border-top: 1px solid $white-dark;
      margin-top: -52.5px;
      &.form-parent-lock-true {
        border: none;
        margin-top: 0;
      }
      &.child-lock-true {
        padding-bottom: 30px;
        margin-bottom: 30px;
      }
    }

    .display-section-title {
      padding: 33px 25px 5px 40px;
    }
    .display-section-radio {
      padding-top: 28px;
    }
  }

  &.close {
    border-radius: 4px;
    margin-bottom: 30px;
  }

  .display-section-title {
    padding: 40px 25px 40px 40px;
    color: $black;
    font-weight: 800;
    @include font-size(18px);
    font-family: 'Mukta', 'Open Sans', 'Helvetica Neue', sans-serif;

    &.Mui-focused {
      color: $black;
    }
  }
  .display-section-radio {
    padding: 0;
  }
}

.ctn-remuneration {
  .ctn-button {
    @include flexbox;
    @include align-items(center);
    .button {
      @include flexbox;
      @include align-items(center);

      &.delete-button {
        color: $red;

        &.Mui-disabled {
          color: $white80;
        }
      }
      &.add-button {
        color: $black;
      }
    }
    .icon {
      margin-right: 5px;

      &.delete-icon {
        margin-top: -4px;
      }
      &.add-icon {
        margin-top: -2px;
      }
    }
  }
}

@media (max-width: 1400px) {
  .form-section {
    .cpn-dashboard {
      .ctn-go-top {
        width: 0;
        .go-top {
          bottom: 23px;
          right: 82px;
          background: $white90;
          padding: 8px 18px;
          border-radius: 4px;
        }
      }
      .MuiSelect-select{
        max-width: 400px;
      }
    }
  }
  html {
    .crisp-client {
      .cc-kv6t[data-last-operator-face=false] {
        .cc-1xry {
          .cc-unoo {
            bottom: 14px!important;
            right: 14px!important;
            .cc-7doi{
              width: 54px!important;
              height: 54px!important;
              .cc-1iv2{
                .cc-15mo[data-is-ongoing=false]{
                  width: 32px!important;
                  height: 26px!important;
                  top: 16px!important;
                  left: 11px!important;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .form-section {
    .cpn-dashboard {
      .MuiTabPanel-root {
        padding: 0;
      }
      .ctn-inputs{
        padding: 15px;

        .section-header{
          @include flex-direction(column-reverse);

          .lock-icon{
            @include flexbox;
            @include justify-content(flex-end)
          }
        }
      }
      .MuiSelect-select{
        max-width: 220px;
      }
    }
    .display-section{
      @include flexbox;
      @include flex-direction(column);
      @include align-items(flex-start);
      padding: 0;

      .display-section-title{
        padding: 10px 15px;
      }
      .display-section-radio{
        padding: 0 15px 10px;
      }
    }
  }
  .dashboard-footer{
    padding: 15px;
    margin-bottom: 60px;
  }
}

.cpn-edit-form-close {
  overflow: auto;
}

.form-section {
  &.edit-form-false {
    .section-subtitle {
      display: none;
    }
    .MuiGrid-item {
      max-width: 100%;
      flex-basis: 100%;
      padding: 7px 12px;

      .ctn-input {
        margin-left: 20px;

        .MuiOutlinedInput-input {
          padding: 12px;
        }
      }

      .MuiFormHelperText-root {
        display: none;
      }
    }
  }
}

.cpn-edit-form-close {
  width: 100%;

  .header{
    padding: 20px;
    text-align: center;
    .image-validation{
      max-width: 300px;
    }

    .title{
      color: $green-light;
      font-weight: 800;
      margin: 0;
    }

    .subtitle{
      @include font-size(20px);
      margin: 0 auto;
      color: $black;
      max-width: 500px;
      line-height: 1.3em;
    }

    .correction{
      margin-top: 20px;
      font-weight: 400;
      @include font-size(17px);
      color: $white60;

      .link{
        cursor: pointer;
        color: $green-light;
        text-decoration: underline;
      }
    }
  }
  .ctn-efc {
    max-width: 700px;
    margin: auto;
    padding: 0 25px 25px;

    .efc-forms {

      .lock-true{
        .ctn-inputs{
          border: 0;

          .MuiGrid-root {
            opacity: 1;
          }

          .title{
            opacity: 1;
          }
        }
      } 
    }

    .MuiAccordion-root{
      margin-bottom: 15px;
      box-shadow: none;

      &::before{
        display: none;
      }
    }

    .MuiAccordionSummary-content {
      @include flexbox;
      @include flex-direction(row);
      @include align-items(center);
      @include justify-content(space-between);
      margin: 20px 15px;

      .left-col {
        @include flexbox;
        @include flex-direction(row);
        @include align-items(center);
        .indication {
          width: 25px;
          height: 25px;
          border-radius: 25px;
          
          padding: 5px;
          &.icon-true {
            color: $green-light;
            border: $green-light 1.5px solid;
          }
          &.icon-false {
            color: $red;
            border: $red 1.5px solid;
          }
        }
        .title {
          margin :0;
          padding-left: 20px;
          font-weight: 600;
          @include font-size(19px);
          color: $black;
        }
      }
      .subtitle{
        color: $white80;
        @include font-size(16px);
        border-radius: 4px;
        .progress{
          margin: 0;
        }
      }
      
    }

    .MuiAccordionDetails-root {
      @include flexbox;
      @include flex-direction(column);

      .ctn-inputs{
        padding: 10px 30px 20px;
        margin-bottom: 0px;
      }

      .section-title{
        color:$black;
        font-size: 18px;
      }

      .section-grid{
        margin: 5px 0 0;

      }
      .MuiGrid-item{
        @include flexbox;
        @include flex-direction(row);
        @include align-items(center);
        padding: 2px 5px;

        .ctn-input-label{
          padding: 0;
          max-width: 50%;

          .MuiInputLabel-root{
          color: $white60;
          line-height: 18px;
          max-width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          }
          &.value-false.is_require-true{
              color: $red;
              .MuiFormLabel-root{
                color: $red;
              }
              .icon{
                margin-right:5px;
              }
          }
        }

        .ctn-input{
          margin-left: 5px;
          overflow: hidden;
          .input{
            margin:0;
            @include font-size(15px);
            color: $white10;
            font-weight: 500;
            line-height: 1.2em;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          &.value-false.is_require-true{
            .error{
              @include flexbox;
              @include flex-direction(row);
              @include align-items(center);
            }
            .input{
              color: $red;
              margin: 0;
              .icon{
                margin-right: 5px;
              }
              .message{
                margin: 0;
                font-weight: 600;
              }
            }
          }
          &.value-false.is_require-false{
            .input{
              color: $white80;
            }
          }
        }
      }
    }
  }
}

html {
  .crisp-client {
    .cc-kv6t {
      .cc-1xry {
        .cc-unoo{
          bottom: 15px!important;
          right: 15px!important;
        }
      }
    }
  }
}