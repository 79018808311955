// @import 'Styles/colors';
@import "../../../../../Styles/colors";
@import "../../../../../Styles/mixins";
// @import 'Styles/mixins';

.manage-contracts {
  .display-links {
    .material-links {
      .filter-row {
        display: contents;
        &.inactive {
          display: none;
        }
      }
      > .MuiPaper-root {
        border-radius: 8px;
        @include box-shadow($h: 0px, $v: 3px, $b: 12px, $s: 1px, $c: rgba(13, 19, 36, 0.1));

        .MuiToolbar-gutters {
          padding-top: 15px;
          padding-bottom: 15px;
        }
      }
      .MuiTypography-h6 {
        font-weight: 800;
        font-family: "Mukta";
        @include font-size(24px);
        color: $black;
      }
      .info-main {
        font-weight: 800;
        @include font-size(18px);
        margin: 0;
        color: $black;
      }
      .info-secondary {
        color: $white60;
        margin: 0;
      }
      .info-state {
        height: 15px;
        width: 15px;
        border-radius: 15px;

        &.state-false {
          background-color: $green;
        }
        &.state-true {
          background-color: $grey;
        }
      }
      .MuiTableCell-paddingNone {
        padding: 16px;
      }
      .Component-paginationRoot-1 {
        padding: 15px;
      }
      tbody.MuiTableBody-root {
        > :nth-last-child(2) {
          display: none;
        }
      }
    }
  }
  #btn-create-contract {
    margin-bottom: 15px;
    background-color: #42b391;
    padding: 10px;
  }
}
.ctn-create-link {
  background: white;
  max-width: 800px;
  padding: 30px 50px 70px;
  margin: auto;
  margin-top: 50px;
  border-radius: 7px;

  .ctn-btn {
    margin-top: 30px;
    width: 100%;

    button {
      width: 100%;
    }
  }
}

#delete-modal {
  .MuiPaper-root {
    max-width: 700px;
    .MuiDialogTitle-root {
      background: #fbe9e7;
      padding: 25px 30px;
      .MuiTypography-root {
        @include flexbox;
        @include align-items(center);
      }
      .modal-title {
        font-weight: 700;
        @include font-size(22px);
        font-family: "Mukta";
        color: #c62828;
        margin: 0;
      }
      .MuiSvgIcon-root {
        color: #c62828;
        margin-right: 15px;
      }
    }
    .MuiDialogContent-root {
      padding: 30px 30px 5px;
      p {
        font-family: "Mukta";
        @include font-size(17px);
      }
    }
    .MuiDialogActions-root {
      padding: 25px 30px;
      span {
        @include font-size(17px);
      }
    }
  }
}

.tooltip-name-false {
  background: transparent;
}

.ctn-status {
  width: 100%;
  .status-select {
    font-weight: 700;
    @include font-size(14px);
    text-transform: uppercase;
    font-family: "Mukta";

    &.status-0 {
      background: $ad-status-black-light;
      color: $ad-status-black-dark;
      .MuiSelect-iconOutlined {
        color: $ad-status-black-dark;
      }
    }
    &.status-1 {
      background: $ad-status-orange-light;
      color: $ad-status-orange-dark;
      .MuiSelect-iconOutlined {
        color: $ad-status-orange-dark;
      }
    }
    &.status-2 {
      background: $ad-status-lavender-light;
      color: $ad-status-lavender-dark;
      .MuiSelect-iconOutlined {
        color: $ad-status-lavender-dark;
      }
    }
    &.status-3 {
      background: $ad-status-purple-light;
      color: $ad-status-purple-dark;
      .MuiSelect-iconOutlined {
        color: $ad-status-purple-dark;
      }
    }
    &.status-4 {
      background: $ad-status-green-light;
      color: $ad-status-green-dark;
      .MuiSelect-iconOutlined {
        color: $ad-status-green-dark;
      }
    }
    &.status-5 {
      background: $ad-status-yellow-light;
      color: $ad-status-yellow-dark;
      .MuiSelect-iconOutlined {
        color: $ad-status-yellow-dark;
      }
    }
    &.status-6 {
      background: $ad-status-red-light;
      color: $ad-status-red-dark;
      .MuiSelect-iconOutlined {
        color: $ad-status-red-dark;
      }
    }
    &.status-7 {
      background: $ad-status-yellow-light;
      color: $ad-status-yellow-dark;
      .MuiSelect-iconOutlined {
        color: $ad-status-yellow-dark;
      }
    }
    &.status-8 {
      background: $ad-status-brown-light;
      color: $ad-status-brown-dark;
      .MuiSelect-iconOutlined {
        color: $ad-status-brown-dark;
      }
    }
  }
  .MuiSelect-select {
    padding: 10px 16px;
    transition: all 0.2s;
  }
  .MuiOutlinedInput-notchedOutline {
    border: 0;
  }
}
.MuiMenu-paper {
  .MuiMenu-list {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
